import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104')
];

export const server_loads = [0];

export const dictionary = {
		"/": [29],
		"/auth": [97],
		"/auth/verify-email": [98],
		"/chrome-extension": [99],
		"/error": [100],
		"/healthcheck/diagnose": [101],
		"/(login)/login": [30,[2]],
		"/ov-docs": [102],
		"/(login)/sign-up": [31,[2]],
		"/(login)/sign-up/hp-verify": [32,[2]],
		"/(user)/user-home": [33,[3]],
		"/(user)/user-profile": [34,[3,4]],
		"/(user)/user-profile/account": [35,[3,4]],
		"/(user)/user-profile/appearance": [36,[3,4]],
		"/(user)/user-profile/overview": [37,[3,4]],
		"/(user)/user-profile/profile": [38,[3,4]],
		"/(user)/verify-email": [39,[3]],
		"/zendesk_app": [103,[27]],
		"/zendesk_app/[community=slug]/copilot": [104,[27,28]],
		"/[community=slug]": [40,[5],[6]],
		"/[community=slug]/beta-sandbox": [45,[5,8],[6]],
		"/[community=slug]/(knowledge-base)/category/[slug]/[uuid]": [41,[5,7],[6]],
		"/[community=slug]/changelog": [46,[5,9],[6]],
		"/[community=slug]/changelog/[slug]/[uuid]": [47,[5,9],[6]],
		"/[community=slug]/community/forum/[...rest]": [49,[5],[6]],
		"/[community=slug]/community/[...rest]": [48,[5],[6]],
		"/[community=slug]/content-library": [50,[5,10],[6]],
		"/[community=slug]/context-sources": [51,[5,11],[6]],
		"/[community=slug]/context-sources/external": [52,[5,11],[6]],
		"/[community=slug]/context-sources/snippets": [53,[5,11,12],[6]],
		"/[community=slug]/context-sources/snippets/[slug]/[uuid]": [54,[5,11,12],[6]],
		"/[community=slug]/demo": [55,[5],[6]],
		"/[community=slug]/(knowledge-base)/doc": [42,[5,7],[6]],
		"/[community=slug]/(knowledge-base)/doc/[slug]/[uuid]": [43,[5,7],[6]],
		"/[community=slug]/forums": [56,[5,13],[6]],
		"/[community=slug]/forums/community-error": [59,[5,13],[6]],
		"/[community=slug]/forums/home": [60,[5,13],[6]],
		"/[community=slug]/forums/members": [61,[5,13],[6]],
		"/[community=slug]/forums/page/[slug]/[uuid]": [62,[5,13],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]": [57,[5,13,14],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [58,[5,13,14],[6]],
		"/[community=slug]/home": [63,[5],[6]],
		"/[community=slug]/insights": [64,[5,15],[6]],
		"/[community=slug]/insights/knowledge-base": [~65,[5,15],[6]],
		"/[community=slug]/insights/workspace": [66,[5,15],[6]],
		"/[community=slug]/join": [67,[16]],
		"/[community=slug]/join/[inviteCode]": [68,[16]],
		"/[community=slug]/outverse-agent": [69,[5,17],[6]],
		"/[community=slug]/outverse-agent/conversation-history": [70,[5,17,18],[6]],
		"/[community=slug]/outverse-agent/conversation-history/[slug]": [71,[5,17,18],[6]],
		"/[community=slug]/outverse-agent/escalation": [72,[5,17,19],[6]],
		"/[community=slug]/outverse-agent/intents": [73,[5,17,20],[6]],
		"/[community=slug]/outverse-agent/sandbox": [74,[5,17,21],[6]],
		"/[community=slug]/outverse-agent/settings": [75,[5,17],[6]],
		"/[community=slug]/(knowledge-base)/section/[slug]/[uuid]": [44,[5,7],[6]],
		"/[community=slug]/settings": [76,[5,22],[6]],
		"/[community=slug]/settings/changelog/settings": [77,[5,22],[6]],
		"/[community=slug]/settings/community/custom-roles": [78,[5,22],[6]],
		"/[community=slug]/settings/community/labels": [79,[5,22],[6]],
		"/[community=slug]/settings/community/manage-members": [80,[5,22],[6]],
		"/[community=slug]/settings/community/settings": [81,[5,22],[6]],
		"/[community=slug]/settings/general/integrations": [82,[5,22],[6]],
		"/[community=slug]/settings/general/overview": [83,[5,22],[6]],
		"/[community=slug]/settings/general/team": [84,[5,22],[6]],
		"/[community=slug]/settings/general/webhooks": [85,[5,22],[6]],
		"/[community=slug]/settings/help-center/content-links": [86,[5,22],[6]],
		"/[community=slug]/settings/help-center/custom-domain": [87,[5,22],[6]],
		"/[community=slug]/settings/help-center/settings": [88,[5,22],[6]],
		"/[community=slug]/widget": [89,[23]],
		"/[community=slug]/workspace": [90,[5],[6]],
		"/[community=slug]/zendesk": [91,[5,24],[6]],
		"/[community=slug]/zendesk/conversation-history": [92,[5,24,25],[6]],
		"/[community=slug]/zendesk/conversation-history/[slug]": [93,[5,24,25],[6]],
		"/[community=slug]/zendesk/insights": [94,[5,24],[6]],
		"/[community=slug]/zendesk/intents": [95,[5,24],[6]],
		"/[community=slug]/zendesk/settings": [96,[5,24],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';